import React from 'react';

import { Main } from '../styles/background';

import { Content } from '../components/layouts/PageContainer/PageContainer';
import { Header } from '../components/layouts/HeaderComponent';
import { Footer } from '../components/layouts/Footer';
import { Container_404 } from '../components/pageComponents/404/404Container';
import { PageContainer } from '../components/containers/PageContainer';
import { HelmetContainer } from '../components/pageComponents/helmet';

const Error_404 = () => {
  let defaultLang = 'en';

  return (
    <PageContainer>
      <HelmetContainer title="404: Page not found" />
      <Main noPadding>
        <Header lang={defaultLang} />
        <Content>
          <Container_404 />
        </Content>
      </Main>
      <Footer />
    </PageContainer>
  );
};

export default Error_404;
